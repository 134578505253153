
<a class="whats-app" href="https://wa.me/{{numero_WhatsAppMessage}}" target="_blank">
    <i class="fa fa-whatsapp whats-app-float">
        <img id="imageWhatsApp" src="assets/img/whatsapp.png" alt="logo">
    </i>
</a>

<div class="navbar-area" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/"><img style="max-width: 290px;" src="assets/img/logo_new1.png" alt="logo"></a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item d-none"><a routerLink="/" class="nav-link">Home</a></li>
                    <li class="nav-item"><a href="elenco-corsi" class="nav-link">Corsi di Formazione <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item text-capitalize" *ngFor="let rowMenu of menuWeb"><a href="/elenco-corsi/{{rowMenu.tag}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{rowMenu.nome}}</a></li>
                        </ul>
                    </li>
                    <li class="nav-item d-none"><a routerLink="/validita-attestati" class="nav-link">Validità Attestato</a>
                    </li>
                    <li class="nav-item"><a routerLink="/contatti" class="nav-link">Contatti</a>
                    </li>
                </ul>
                <ul class="navbar-nav for-responsive">
                    <li *ngIf="detailUser" class="nav-item"><a href="elenco-corsi/i-miei-corsi" class="nav-link text-uppercase fw-bolder" style="color: var(--mainColor);">I miei corsi - corsi acquistati</a>
                    </li>
                    <li *ngIf="detailUser" class="nav-item"><a (click)="logout()" class="nav-link text-danger text-uppercase fw-bolder">Effettua il Logout</a>
                    </li>
                    <li *ngIf="!detailUser" class="nav-item"><a href="/login" class="nav-link text-uppercase fw-bolder" style="color: var(--mainColor);">Effettua il Login</a>
                    </li>
                </ul>
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item"><a  class="nav-link">Home</a>
                    </li>
                    <li class="nav-item"><a  class="nav-link">Corsi di Formazione <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item text-capitalize" *ngFor="let rowMenu of menuWeb"><a href="/elenco-corsi/{{rowMenu.tag}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{rowMenu.nome}}</a></li>
                        </ul>
                    </li>
                    <li class="nav-item d-none"><a routerLink="/validita-attestati"  class="nav-link">Validità Attestato</a>
                    </li>
                    <li class="nav-item"><a routerLink="/contatti" class="nav-link">Contatti</a>
                    </li>
                </ul>
            </div>
            <div class="others-option d-flex align-items-center">
                <div class="option-item d-none">
                    <div id="imgBtnCart" class="cart-btn">
                        <a routerLink="/cart"><i class='flaticon-shopping-cart'></i><span>3</span></a>
                    </div>
                </div>
                <div class="option-item">
                    <a *ngIf="!detailUser" id="btnLogin" href="/login" class="default-btn" style="cursor: pointer;"><i class="flaticon-user"></i>
                        Accedi / Login<span></span>
                    </a>
                    <a *ngIf="detailUser" id="btnLogin" href="elenco-corsi/i-miei-corsi" class="default-btn" style="cursor: pointer;"><i class="flaticon-agenda"></i>
                        I miei corsi<span></span>
                    </a>
                    <a *ngIf="detailUser" id="btnLogin" class="default-btn ms-1" (click)="logout()" style="padding-left: 30px; background-color: var(--bs-danger); cursor: pointer;">
                        Esci
                    </a>
                </div>

                </div>
        </nav>
    </div>
</div>
